import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'
function Mail() {
  const ScrollToTop =()=>{
    window.scrollTo({
      top:0,
      behavior:'smooth'
    });
  }
  return (
    <div>
      <section class="sect-cls">
        <div class="col_bg_3">
            <Header/>
            <div class="container">
                <div class="center-vertically2">
                    <div class="background4 border">
                        <h3 className='Resume'>Send your Resume to <br/> Below Mail</h3>
                        <div className='mail1'>
                        <button type="button" class="btn text-center btn-lg rounded-pill border border-dark mail1"><img src="./images/Social media logo.png"/><a href="mailto:hello@buzzler.in" style={{textDecoration:'none',color:'#333333'}}>&nbsp;&nbsp;hello@buzzler.in</a></button>
                        </div>
                        <hr/>
                        <p className='contact1'><Link to={'/Contact'} onClick={ScrollToTop} className='contact1'>Contact Us</Link><span style={{color:"black"}}> for enquiry and support</span></p>
                        <p className='contact1' style={{color:'black'}}>Thank You</p>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </section>
    </div>
  )
}

export default Mail
