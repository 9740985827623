import React from "react";
import Header from "../components/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faPhoneVolume,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import Footer from "../components/Footer";
function Contact() {
  return (
    <div>
      <section class="sect-cls">
        <div class="col_bg_3">
          <div class="row rowcust">
            <Header />
          </div>
          <div class="container mt-5">
            <div class="row align">
              <div class="col-lg-7 col-md-7 col-xl-7 col-xxl-7 col-sm-12 col-xs-12">
                <div class="background">
                  <div class="col_bg-2">
                    <div>
                      <div class="container col-md-12">
                        <h1 className="contact">
                          Contact <span class="us">Us</span>
                        </h1>
                        <form id="contactForm">
                          <div class="form-group mt-5">
                            <input
                              type="text"
                              class="form-control form-control-1"
                              placeholder="Your Name"
                              name="name"
                              required
                            />
                          </div>
                          <div class="form-group mt-4">
                            <input
                              type="email"
                              class="form-control form-control-1"
                              placeholder="Email"
                              name="email"
                              required
                            />
                          </div>
                          <div class="form-group mt-4">
                            <textarea
                              class="form-control form-control-1"
                              id="messageBox"
                              placeholder="Message"
                              name="messageBox"
                              rows="4"
                              required
                            ></textarea>
                          </div>
                          <div class="form-check mt-4">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              id="checkBox"
                              name="checkBox"
                              required
                            />
                            <label class="form-check-label" for="checkBox">
                              I would like to receive the newsletter.
                            </label>
                          </div>
                          <div className="btn-pink btn-text mt-4">
                            <button class="btn btn-md text-white">
                              Submit
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 mt-4">
                <div class="">
                  <p className="get-in">
                    Get in touch with us, we love to hear and respond to{" "}
                    <br></br>your messages.
                  </p>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.5253765500497!2d78.37664357420597!3d17.434550583460705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb93e37831f177%3A0x32d4499d34baaa78!2sT-Hub%20Phase%202!5e0!3m2!1sen!2sin!4v1708936451341!5m2!1sen!2sin"
                    width="80%"
                    height="auto"
                    className="map-margin"
                    style={{ border: 0 }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                  <div className="get-in">
                    <p class="mt-2">
                      <FontAwesomeIcon icon={faLocationDot} />
                      &nbsp;&nbsp;T-Hub 2.0, 20, Inorbit Mall Rd, Vittal Rao
                      Nagar,<br></br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Madhapur, Hyderabad, Telangana 500081
                    </p>
                    <p>
                      <FontAwesomeIcon icon={faPhoneVolume} />
                      &nbsp;&nbsp;+91 91547 91547
                    </p>
                    <p>
                      <FontAwesomeIcon icon={faEnvelope} />
                      &nbsp;&nbsp;hello@buzzler.in
                    </p>
                  </div>
                </div>
                <div class="text-end justify-content-between icons">
                  <div>
                    <a
                      href="https://www.facebook.com/balertofficial/"
                      target="_blank"
                    >
                      <FaFacebook size={20} color="black" />
                    </a>
                    &nbsp;&nbsp;
                    <a
                      href="https://www.instagram.com/balert.in/"
                      target="_blank"
                    >
                      <FaInstagram size={20} color="black" />
                    </a>
                    &nbsp;&nbsp;
                    <a href="https://twitter.com/balert_in" target="_blank">
                      <FaTwitter size={20} color="black" />
                    </a>
                    &nbsp;&nbsp;
                    <a>
                      <FaYoutube size={20} color="black" />
                    </a>
                    &nbsp;&nbsp;
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </div>
  );
}

export default Contact;
