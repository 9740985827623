import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { BrowserRouter,Link } from 'react-router-dom'
function CRM() {
    const ScrollToTop=()=>{
        window.scrollTo({
            top:0,
            behavior:'smooth'
        })
    }
  return (
    <div>
      <section class="sect-cls">
            <div class="navtab_bg-1">
                <div class="row rowcust">
                    <Header/>
                </div>
                <div class="container class-container">
                    <div class="row">
                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 align">
                            <h2 class="mainheading-4"><span class='customer'>Customer RelationShip</span> <br></br>Management</h2>
                            <p className='outstanding-text'>Look at out outstanding services, CRM got us <br></br>to bigger heights.</p>
                            <div className='btn-pink btn-text mt-5'>
                                <Link to={'/Contact'} onClick={ScrollToTop}><button class="btn btn-lg text-white">Get Service</button></Link>
                            </div>
                        </div>
                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12">
                            <img src="images/CRM.png" class="img-fluid ai"/>
                        </div>
                    </div>
                </div>
            </div>
            <section>
                <div class="container">
                    <div class="row align aligns">
                        <div class="col-md-3 col-lg-3 col-sm-6 col-xs-12">
                            <h2 className='number'>Our numbers tells<br></br>more about us</h2>
                        </div>
                        <div class="col-md-3 col-lg-3 col-sm-6 col-xs-12">
                            <h2 className='mainheading'>10+</h2>
                            <p className='text-app'>New services added every year <br></br>with expertise.</p>
                        </div>
                        <div class="col-md-3 col-lg-3 col-sm-6 col-xs-12">
                            <h2 className='mainheading'>20K+</h2>
                            <p className='text-app'>Clients trusted our loyal services<br></br>and got benefited.</p>
                        </div>
                        <div class="col-md-3 col-lg-3 col-sm-6 col-xs-12">
                            <h2 className='mainheading'>1.9k+</h2>
                            <p className='text-app'>Feedbacks from all over the <br></br>clients.</p>
                        </div>
                    </div>
                </div>
            </section>
            <div class="container mt-5 ">
                <hr class="custom-horizontal-line"/>
                <h2 class="text-center line-2 number">Our Clients</h2>
            </div>
            <div class="service_bg">
            {/* <div class="container"> */}
                {/* <div class="row text-center">
                    <div class="col-md-3 col-lg-3 col-sm-6 col-xs-12">
                        <img src="./images/Balert logo.png" alt="" className='logos'/>
                    </div>
                    <div class="col-3 col-lg-3 col-sm-6 col-xs-12 ">
                        <img src="./images/land-roverin-logo.png" alt="" className='logos'/>
                    </div>
                    <div class="col-3 col-lg-3 col-sm-6 col-xs-12">
                        <img src="./images/the-avengers 2.png" alt="" className='logos'/>
                    </div>
                    <div class="col-3 col-lg-3 col-sm-6 col-xs-12">
                        <img src="./images/etsy.png" alt="" className='logos'/>
                    </div>
                </div> */}
                <div class="container">
                    <div class="row text-center">
                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 container-logo1">
                            <img src="./images/Balert logo.png" alt="" className='buzzler-logo1'/>
                            <img src="./images/land-roverin-logo.png" alt="" className='logo1'/>
                            <img src="./images/the-avengers 2.png" alt="" className='logo1'/>
                            <img src="./images/etsy.png" alt="" className='logo1'/>
                        </div>
                    </div>
                </div>
                <section class="sect-cls1 rounded shadow">
                    <div class="container mt-5">
                        <div class="row">
                            <div class="col-md-8 col-lg-8 col-sm-12 col-xs-12 align">
                                <p className='solutions text-2'>Buzzler CRM services</p>
                                <div class="vertical-line">
                                    <p class="text-1">The real essence of Customer RelationShip <br></br>Management thrives on the innovation that  <br></br>stakeholders,insights, and expertise will actualize <br></br>in the interconnected future.</p>
                                </div>
                            </div>
                            <div class="col-md-4 col-lg-4 col-sm-8 col-xs-12">
                                <img src="./images/Office management (1).gif" alt="" class="img-fluid mt-5 mb-5"/>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="container mt-5">
                    <div class="row">
                        <div class="col-md-8 col-lg-8 col-sm-8 col-xs-12 align">
                            <p className='solutions'>perks</p>
                            <p className='text-safe lh-sm'>Safeguard the future of your <br></br>enterprise with customized solutions <br></br>designed specifically for your needs.</p>
                            <div class="vertical-line">
                                <ul className='text-list'>
                                    <li>Explore fresh opportunities to transform your business.</li>
                                    <li>Evolve, adjust, and thrive within purpose-driven environments.</li>
                                    <li>Craft innovative business frameworks and expand swiftly.</li>
                                    <li>Harness the potential of emerging technologies.</li>
                                    <li>Speed up innovation and unleash fresh avenues for growth.</li>
                                </ul>
                            </div>
                            </div>
                        </div>
                    </div>
                {/* </div> */}
                <section>
                    <div class="container">
                        <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12 d-flex justify-content-end text-expert">
                            <Link to={'/Contact'} onClick={ScrollToTop}><button class="btn btn-light rounded-pill btn-lg justify-content-end mb-5"><span><img src="./images/Arrow.png" alt="" width="50" height="50"/></span>&nbsp;&nbsp;Talk to our Experts</button></Link>
                        </div>
                    </div>
                </section>
            </div>
            <Footer/>
        </section>
    </div>
  )
}

export default CRM
