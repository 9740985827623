import React from "react";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { BrowserRouter, Link } from "react-router-dom";
function Home() {
  const [gifsrc, setGifsrc] = useState("");
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    const dynamicUrl = "images/Data extraction.gif";
    setGifsrc(dynamicUrl);
  }, []);
  return (
    <div>
      <section class="sect-cls">
        <div class="navtab_bg">
          <div class="row rowcust">
            <Header />
          </div>
          <div class="container vertically">
            <div class="row">
              <div class="col-lg-offset-1 col-lg-6 col-md-offset-1 col-md-12 col-sm-12 col-xs-12">
                <h2 class="mainheading">
                  Explore software <br></br>innovation:{" "}
                  <span className="sub-heading">
                    effortless<br></br>fortified, and enlightening{" "}
                  </span>
                </h2>
                <p className="small-heading">
                  Experience streamlined solutions, robust security, and{" "}
                  <br></br>insightful advancements.
                </p>
                <div className="btn-pink btn-text">
                  <Link to={"/Contact"} onClick={scrollToTop}>
                    <button class="btn btn-lg text-white">Start Now</button>
                  </Link>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-end">
                <img
                  src={"images/Data extraction.gif"}
                  class="img-fluid"
                  width="85%"
                  height="85%"
                  id="myGif"
                />
              </div>
            </div>
          </div>
        </div>
        <section>
          <div class="container container-2">
            <div class="row">
              <div class="col col-md-6 col-sm-6 col-xs-12 col-lg-3 mt-5">
                <div class="square-box-1">
                  <img src="images/accessibility-outline 1.png" />
                  <h2 className="heading1 mt-2">
                    Easy<br></br>Access
                  </h2>
                  <p className="sub1">
                    Seamless access to <br></br> services.
                  </p>
                </div>
                <div class="square-box-1">
                  <img src="images/analytics-outline 1.png" />
                  <h2 className="heading1 mt-2">Use You Connect</h2>
                  <p className="sub1">
                    We are widely <br></br>available.
                  </p>
                </div>
              </div>
              <div class="col col-md-6 col-sm-6 col-xs-12 col-lg-3">
                <div class="square-box-1">
                  <img src="images/cash-outline 1.png" />
                  <h2 className="heading1 mt-2">Affordable</h2>
                  <p className="sub1">
                    For all type of <br></br>categories.
                  </p>
                </div>
                <div class="square-box-1">
                  <img src="images/receipt-outline 1.png" />
                  <h2 className="heading1 mt-2">
                    Manage <br></br>learning
                  </h2>
                  <p className="sub1">Easy to manage</p>
                </div>
              </div>
              <div class="col-md-12 col-lg-6 main">
                <h2 class="mainheading-1">
                  Make & Share Work<br></br>With Community
                </h2>
                <p className="small-heading-1">
                  We provide high quality services <br></br>and products to the
                  users.
                </p>
                <div>
                  <Link to={"/Services"} className="link" onClick={scrollToTop}>
                    <p>
                      Learn More <FontAwesomeIcon icon={faArrowRight} />
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="cust_bg">
            <div class="container">
              <div class="row text-center">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 container-logo1">
                  <img src="./images/Balert logo.png" alt="" className="buzzler-logo" />
                  <img
                    src="./images/land-roverin-logo.png"
                    alt=""
                    className="logo"
                  />
                  <img
                    src="./images/the-avengers 2.png"
                    alt=""
                    className="logo"
                  />
                  <img src="./images/etsy.png" alt="" className="logo" />
                </div>
              </div>
            </div>
            <div class="container">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-5">
                  <img
                    src="images/launch-image.png"
                    alt=""
                    class="img-fluid"
                    width={400}
                    height={400}
                  />
                </div>
                <div
                  class="col-lg-6 col-md-6 col-sm-12 col-xs-12 align"
                  style={{ marginTop: "8em" }}
                >
                  <div className="btn-service rounded-pill">
                    <p>
                      &nbsp;&nbsp;&nbsp;
                      <img
                        src="images/Circuit Icon.png"
                        alt=""
                        width="20"
                        height="20"
                      />
                      <Link
                        className="services"
                        to={"/Services"}
                        onClick={scrollToTop}
                        style={{ textDecoration: "none" }}
                      >
                        {" "}
                        Our Services
                      </Link>
                    </p>
                  </div>
                  <h2 class="mainheading-2">Launch with the Buzzler</h2>
                  <p className="small-heading2">
                    A Software solutions company where you can get loyal{" "}
                    <br></br>and quality services.
                  </p>
                </div>
              </div>
              <div class="row rowcust mt-5">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-5 alignL">
                  <p className="solutions">software solutions</p>
                  <h2 class="mainheading-2">Solution Gets Easier</h2>
                  <p className="small-heading3">
                    “Experience streamlined innovation for <br />a seamless
                    digital future."
                  </p>
                  <div>
                    <Link
                      className="link"
                      to={"/Services"}
                      onClick={scrollToTop}
                    >
                      <p>
                        Learn More <FontAwesomeIcon icon={faArrowRight} />
                      </p>
                    </Link>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <img
                    src="images/Finding brilliant ideas.gif"
                    alt=""
                    class="img-fluid"
                    width={400}
                    height={400}
                  />
                </div>
              </div>
              <div class="row rowcust mt-5">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-5 office-management">
                  <img
                    src="images/Office management.gif"
                    alt=""
                    class="img-fluid"
                    width={400}
                    height={400}
                  />
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-5 align">
                  <p className="solutions">Management</p>
                  <h2 class="mainheading-2">
                    Smart <br></br>Management
                  </h2>
                  <p className="small-heading3">
                    “Efficient, intelligent management <br />
                    solutions for a smarter,streamlined <br />
                    workflow."
                  </p>
                  <div>
                    <Link
                      className="link"
                      to={"/Services"}
                      onClick={scrollToTop}
                    >
                      <p>
                        Learn More <FontAwesomeIcon icon={faArrowRight} />
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="bg_bg">
            <div class="container">
              <div class="row rowcust">
                <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 align">
                  <h1 className="responsive customer-text">
                    What Our <br></br>
                    <span style={{ color: "#5C0E62" }}>Customer</span> Are{" "}
                    <br></br>saying
                  </h1>
                  <p className="Except-text">
                    Exceptional service! Easy-to-use software with <br></br>
                    robust security and insightful features. Highly <br></br>
                    recommended.
                  </p>
                </div>
                <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12 mt-5">
                  <div class="text-center img-small-1">
                    <img
                      src="images/manager-clipart-store-manager-9.png"
                      alt=""
                      class="img-fluid"
                      width={250}
                      height={250}
                    />
                    <p className="Except-text mt-5">
                      Exceptional service! Easy-to-use software <br></br>with
                      robust security and insightful
                      <br></br>features. Highly recommended
                    </p>
                    <h2 className="Tony-text">TONY STARK</h2>
                    <h6 class="CEO-text">CEO at Stark.com</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="p-4 container text-center box-modal border rounded shadow">
          <h1 className="mainheading-2">What Are You Waiting For? </h1>
          <div className="btn-pink btn-text1">
            <Link to={"/Contact"} onClick={scrollToTop}>
              <button class="btn btn-lg text-white mt-2">Try Buzzler</button>
            </Link>
          </div>
        </div>
        <div className="mt-5">
          <Footer />
        </div>
      </section>
    </div>
  );
}

export default Home;

{
  /* 

 */
}
