import React from "react";
import Header from "../components/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faHandshake } from "@fortawesome/free-solid-svg-icons";
import Footer from "../components/Footer";
import { BrowserRouter, Link } from "react-router-dom";
function Career() {
  const ScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <section class="sect-cls">
        <div class="row rowcust">
            <Header/>
        </div>
        <section>
          <div class="col_bg_7">
            <div class="container center-vertically align">
              <div>
                <h1 class="text-white create">
                  Create a vision <br></br>
                </h1>
                <span className="create create1">you can stand behind.</span>
                <p class="text-white small-heading">
                  We provide high quality services <br></br>and products to the
                  users.
                </p>
                <div className="text-expert">
                  <Link to={"/Contact"} onClick={ScrollToTop}>
                    <button class="btn btn-light rounded-pill btn-md justify-content-end mt-5">
                      <span>
                        <img
                          src="./images/Arrow.png"
                          alt=""
                          width="50"
                          height="50"
                        />
                      </span>
                      &nbsp;&nbsp;Learn More
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div
            class="container text-center"
            style={{ marginTop: "5em", marginBottom: "5em" }}
          >
            <h1 className="text-gradient">
              <span style={{ color: "black" }}>What make BUZZLER A</span>{" "}
              <br></br>great place to work
            </h1>
          </div>
        </section>
        <section>
          <div class="body_bg-7">
            <div class="container align">
              <div class="row justify-content-between col-xs-12 col-sm-12 col-md-12">
                <div class="card" style={{ width: "16em" }}>
                  <img
                    class="card-img-top"
                    src="./images/Company-Values.png"
                    alt="Card image cap"
                  />
                  <div class="card-body">
                    <p className="company">Company Values</p>
                    <p class="card-text inner-text">
                      Trust, learning, honesty, and co-operation are the pillars
                      that sit at the core of what we do.
                    </p>
                  </div>
                </div>
                <div class="card" style={{ width: "16em" }}>
                  <img
                    class="card-img-top"
                    src="./images/Friendly-Atmosphere.png"
                    alt="Card image cap"
                  />
                  <div class="card-body">
                    <p className="company1">Friendly atmosphere</p>
                    <p class="card-text inner-text">
                      We hold a positive attitude in order to foster respect and
                      decency for our entire team.
                    </p>
                  </div>
                </div>
                <div class="card" style={{ width: "16em" }}>
                  <img
                    class="card-img-top"
                    src="./images/Work-lifeBalance.png"
                    alt="Card image cap"
                  />
                  <div class="card-body">
                    <p className="company">Work-life Balance</p>
                    <p class="card-text inner-text">
                      For us, a healthy personal life is just as important as
                      the time you spend in the office.
                    </p>
                  </div>
                </div>
                <div class="card" style={{ width: "16em" }}>
                  <img
                    class="card-img-top"
                    src="./images/EveryDay-Grow.png"
                    alt="Card image cap"
                  />
                  <div class="card-body">
                    <p className="company">Everyday Grow</p>
                    <p class="card-text inner-text">
                      We provide the necessary support to ensure your skills
                      never stop growing.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="background-3 mt-5">
                <div class="container">
                  <h1 class="text-center mainheading-14">
                    <span style={{ color: "black" }}>
                      Take a peep at what goes on at
                    </span>{" "}
                    <br></br>BUZZLER!
                  </h1>
                  <img
                    src="./images/Group-Image.png"
                    alt=""
                    width="75%"
                    class="mx-auto d-block mt-5"
                  />
                </div>
              </div>
            </div>
            <section>
              <div class="container text-center">
                <h1 className="mainheading-13">
                  Don't just take our word for it!
                </h1>
                <h5 className="support1">
                  See the feedback from your teammates
                </h5>
              </div>
            </section>
            <section>
              <div class="container mt-5">
                <div class="row">
                  <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 align">
                    <div class="background-3 border border-primary shadow">
                      <div style={{ marginLeft: "0em" }}>
                        <h6 className="text67">
                          " I do admire the company culture and, I like that.
                          Visioncraft is truly the place where good attitudes
                          have blended with proficiency. If you’re going to
                          craft something great and you have a strong eagerness
                          to do that, Visioncraft will remove all hassles in
                          your journey "
                        </h6>
                        <h6 className="name">NAME</h6>
                        <p className="designation">Designation</p>
                        <p style={{ marginLeft: "2em" }} className="profile">
                          <button
                            type="button"
                            className="btn btn-lg border border-dark rounded-pill"
                          >
                            <img src="./images/linkedin.png" alt="" />
                            &nbsp;&nbsp;LinkedIn Profile
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
        <section>
          <div class="body_bg-1">
            <div class="container text-center col-md-12">
              <h1 class="text-gradient11">How to Join in BUZZLER?</h1>
              <p className="join">
                Joining us couldn't be easier! Check out our application process
                down below. If you have the <br></br>talent we need, then we'll
                be meeting you soon!
              </p>
            </div>
            <div class="container mt-5 mb-5">
              <div class="row">
                <div class="col-md-6"></div>
                <div class="col-md-6">
                  <div class="d-flex align">
                    <h6 class="rounded-circle two">01</h6>
                    <div style={{ marginLeft: "4%" }}>
                      <h4 className="role">Find a role that suits you</h4>
                      <p className="dis">
                        Discover open positions and find your <br></br>desired
                        one in the Visioncraft website, job <br></br>listings or
                        social media.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="d-flex justify-content-end alignR">
                    <div>
                      <h4 className="role">Send your application</h4>
                      <p className="dis">
                        Some simple questions should be <br></br>answered and
                        your contact information <br></br>isrequired
                      </p>
                    </div>
                    <h6 class="rounded-circle two" style={{ marginLeft: "2%" }}>
                      02
                    </h6>
                  </div>
                </div>
                <div class="col-md-6"></div>
              </div>
              {/* <!-- ------------------------------------------ --> */}
              <div class="row">
                <div class="col-md-6"></div>
                <div class="col-md-6">
                  <div class="d-flex align">
                    <h6 class="rounded-circle two">03</h6>
                    <div style={{ marginLeft: "4%" }}>
                      <h4 className="role">Receive your interview invite</h4>
                      <p className="dis">
                        We review all applications within 3 <br></br>working
                        days and send invitation to
                        <br></br>candidates.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="d-flex justify-content-end alignR">
                    <div>
                      <h4 className="role">Choose an interview slot</h4>
                      <p className="dis">
                        You will have a friendly discution with <br></br>the CEO
                        and your supervisor to talk
                        <br></br>about the work, life and etc.
                      </p>
                    </div>
                    <h6 class="rounded-circle two" style={{ marginLeft: "2%" }}>
                      04
                    </h6>
                  </div>
                </div>
                <div class="col-md-6"></div>
              </div>
              {/* <!-- ------------------------------------------ --> */}
              <div class="row">
                <div class="col-md-6"></div>
                <div class="col-md-6">
                  <div class="d-flex align">
                    <h6 class="rounded-circle two">05</h6>
                    <div style={{ marginLeft: "4%" }}>
                      <h4 className="role">Preliminary Interview</h4>
                      <p className="dis">
                        Sometimes, we ask candidates to <br></br>participate in
                        some technical challenge
                        <br></br>that is designated to demonstrate <br></br>
                        candidates' proficiency.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="d-flex justify-content-end alignR">
                    <div>
                      <h4 className="role">Meet the your teammates</h4>
                      <p className="dis">
                        To us is crucial to make sure all team <br></br>members
                        feel comfortable. It is why we
                        <br></br>do try to have diverse but culturally <br></br>
                        fitted team members.
                      </p>
                    </div>
                    <h6 class="rounded-circle two" style={{ marginLeft: "2%" }}>
                      06
                    </h6>
                  </div>
                </div>
                <div class="col-md-6"></div>
              </div>
              {/* <!-- ------------------------------------------ --> */}
              <div class="row">
                <div class="col-md-6"></div>
                <div class="col-md-6">
                  <div class="d-flex align">
                    <h6 class="rounded-circle two">07</h6>
                    <div style={{ marginLeft: "4%" }}>
                      <h4 className="role">Interview with our CEO</h4>
                      <p className="dis">
                        Your colleagues are waiting for you to <br></br>say a
                        warm welcome.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="d-flex justify-content-end alignR">
                    <div>
                      <h4 className="role">Start a new journey!</h4>
                    </div>
                    <h6 class="rounded-circle" style={{ marginLeft: "2%" }}>
                      <FontAwesomeIcon icon={faHandshake}></FontAwesomeIcon>
                    </h6>
                  </div>
                </div>
                <div class="col-md-6"></div>
              </div>
            </div>
          </div>
        </section>
        <div class="body-1">
          <section>
            <h1></h1>
            <div class="container">
              <h1 class="text-center mb-5 text-gradient">
                We have 10 open positions now!
              </h1>
              <div class="row align">
                <div class="col-md-3">
                  <ul class="lists">
                    <li style={{ fontSize: "20px", color: "black" }}>
                      All positions (17)
                    </li>
                    <li>Engineering (4)</li>
                    <li>Product (1)</li>
                    <li>Design (2)</li>
                    <li>Operation (1)</li>
                    <li>Marketing (2)</li>
                  </ul>
                  <p className="people">
                    We are always seeking talented people. In case you cannot
                    find your desired position here, please send us your
                    LinkedIn profile and give us your contact information. We
                    will be in touch.{" "}
                  </p>
                  <div className="profile">
                    <Link to={"/Mail"} onClick={ScrollToTop}>
                      <button class="btn btn-md btn-light rounded-pill border">
                        <img src="./images/linkedin.png" alt="" />
                        &nbsp;&nbsp;&nbsp;Share your LinkedIn Profile
                      </button>
                    </Link>
                  </div>
                </div>
                <div class="col-md-9 align">
                  <div class="card card-body">
                    <h3 class="card-title">Full-Stack Developers</h3>
                    <div className="d-flex intern">
                      <button class="btn btn-light btn-md border rounded-pill intern-btn mt-1 mb-2">
                        intern
                      </button>
                      &nbsp;&nbsp;
                      <button class="btn btn-light btn-md border rounded-pill intern-btn mt-1 mb-2">
                        Full-time
                      </button>
                    </div>
                    <p class="card-text">
                      Due to growing workload, we are looking for experienced
                      and talented Full-Stack Developers to join our fast-paced
                      Engineering team. You will work closely with Product,
                      Design and Marketing to analyze, develop, debug, test,
                      roll-out and support new and existing product features.
                    </p>
                    <div className="see see1">
                      <button class="btn btn-dark btn-md border rounded-pill mt-1 mb-2 see">
                        See positions&nbsp;&nbsp;
                        <FontAwesomeIcon icon={faArrowRight} />{" "}
                      </button>
                    </div>
                  </div>
                  <div class="card card-body mt-2">
                    <h3 class="card-title">Full-Stack Developers</h3>
                    <div className="d-flex intern">
                      <button class="btn btn-light btn-md border rounded-pill intern-btn mt-1 mb-2">
                        intern
                      </button>
                      &nbsp;&nbsp;
                      <button class="btn btn-light btn-md border rounded-pill intern-btn mt-1 mb-2">
                        Full-time
                      </button>
                    </div>
                    <p class="card-text">
                      Due to growing workload, we are looking for experienced
                      and talented Full-Stack Developers to join our fast-paced
                      Engineering team. You will work closely with Product,
                      Design and Marketing to analyze, develop, debug, test,
                      roll-out and support new and existing product features.
                    </p>
                    <div className="see see1">
                      <button class="btn btn-dark btn-md border rounded-pill mt-1 mb-2 see">
                        See positions&nbsp;&nbsp;
                        <FontAwesomeIcon icon={faArrowRight} />{" "}
                      </button>
                    </div>
                  </div>
                  <div class="card card-body mt-2">
                    <h3 class="card-title">Full-Stack Developers</h3>
                    <div className="d-flex intern">
                      <button class="btn btn-light btn-md border rounded-pill intern-btn mt-1 mb-2">
                        intern
                      </button>
                      &nbsp;&nbsp;
                      <button class="btn btn-light btn-md border rounded-pill intern-btn mt-1 mb-2">
                        Full-time
                      </button>
                    </div>
                    <p class="card-text">
                      Due to growing workload, we are looking for experienced
                      and talented Full-Stack Developers to join our fast-paced
                      Engineering team. You will work closely with Product,
                      Design and Marketing to analyze, develop, debug, test,
                      roll-out and support new and existing product features.
                    </p>
                    <div className="see see1">
                      <button class="btn btn-dark btn-md border rounded-pill mt-1 mb-2 see">
                        See positions&nbsp;&nbsp;
                        <FontAwesomeIcon icon={faArrowRight} />{" "}
                      </button>
                    </div>
                  </div>
                </div>
                <hr className="line" style={{ marginTop: "10em" }} />
              </div>
            </div>
            <div class="container" style={{ marginTop: "10em" }}>
              <div class="row rowcust align">
                <div class="col-md-6 col-sm-12 col-xs-12 col-lg-6">
                  <h2 className="mainheading">
                    Browse our <br></br>Case Study
                  </h2>
                  <p className="text-66">
                    "Explore our Case Studies for in-depth insights <br />
                    into successful projects."
                  </p>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12 col-lg-6">
                  <div class="d-flex justify-content-between">
                    <p class="mt-2 one">01</p>
                    <h3 className="arti">Artificial Intelligence</h3>
                    <FontAwesomeIcon icon={faArrowRight} className="arti1" />
                  </div>
                  <p class="text-center case">3 Case Studies</p>
                  <hr style={{ width: "100%" }} />
                  <div class="d-flex justify-content-between">
                    <p class="mt-2 one">02</p>
                    <h3 className="arti">Machine Learning</h3>
                    <FontAwesomeIcon icon={faArrowRight} className="arti1" />
                  </div>
                  <p class="text-center case">3 Case Studies</p>
                  <hr style={{ width: "100%" }} />
                  <div class="d-flex justify-content-between">
                    <p class="mt-2 one">03</p>
                    <h3 className="arti">Web Development</h3>
                    <FontAwesomeIcon icon={faArrowRight} className="arti1" />
                  </div>
                  <p class="text-center case">3 Case Studies</p>
                  <hr style={{ width: "100%" }} />
                  <div class="d-flex justify-content-between">
                    <p class="mt-2 one">04</p>
                    <h3 className="arti">CRM</h3>
                    <FontAwesomeIcon icon={faArrowRight} className="arti1" />
                  </div>
                  <p class="text-center case">3 Case Studies</p>
                  <hr style={{ width: "100%" }} />
                  <div class="d-flex justify-content-between">
                    <p class="mt-2 one">05</p>
                    <h3 className="arti">App Development</h3>
                    <FontAwesomeIcon icon={faArrowRight} className="arti1" />
                  </div>
                  <p class="text-center case">3 Case Studies</p>
                  <div>
                    <button class="btn btn-light rounded-pill btn-lg justify-content-end mt-5 mb-5 text-expert">
                      <span>
                        <img
                          src="./images/Arrow.png"
                          alt=""
                          width="50"
                          height="50"
                        />
                      </span>
                      &nbsp;&nbsp;More&nbsp;&nbsp;&nbsp;&nbsp;
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </section>
    </div>
  );
}

export default Career;
