import logo from './logo.svg';
import './App.css';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Home from './Pages/Home'
import Services from './Pages/Services'
import About from './Pages/About'
import Contact from './Pages/Contact'
import Career from './Pages/Career'
import ML from './Pages/ML'
import AI from './Pages/AI'
import WebDev from './Pages/WebDev'
import CRM from './Pages/CRM'
import AppDev from './Pages/AppDev'
import Mail from './Pages/Mail';
function App() {
  return (
    <div className="App">
      <BrowserRouter scrollRestoration="auto">
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/Services' element={<Services/>}/>
          <Route path='/About' element={<About/>}/>
          <Route path='/Contact' element={<Contact/>}/>
          <Route path='/Career' element={<Career/>}/>
          <Route path='/artificial_intelligence' element={<AI/>}/>
          <Route path='/machine_learning' element={<ML/>}/>
          <Route path='/web_development' element={<WebDev/>}/>
          <Route path='/CRM' element={<CRM/>}/>
          <Route path='/app_development' element={<AppDev/>}/>
          <Route path='/Mail' element={<Mail/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
