import React from 'react'
import { BrowserRouter,Link } from 'react-router-dom'

function Footer() {
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      };
  return (
    <div>
      <footer class="footcls">
        <div class="footer_bg">
            <div class="container">
                <div class="row rowcust">
                    <div class="col-lg-2 col-md-2 col-sm-6 col-xs-12  ftlinks">
                        <ul className='animated-text slide-in-right'>
                            <li><h5 class="p-class">Products</h5></li>                 
                            <li><Link to={'/Services'} onClick={scrollToTop}><a href="#">Software</a></Link></li>
                            <li><Link to={'/Services'} onClick={scrollToTop}><a href="#">Services </a></Link></li>
                            <li><Link to={'/Services'} onClick={scrollToTop}><a href="#">Products </a></Link></li>
                            <li><Link to={'/Services'} onClick={scrollToTop}><a href="#">Innovations </a></Link></li>
                            <li><Link to={'/Services'} onClick={scrollToTop}><a href="#">Integrations</a></Link></li>
                        </ul>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 ftlinks">
                        <ul>
                            <li><h5 class="p-class">Use Cases</h5></li>
                            <li><Link to={'/artificial_intelligence'} onClick={scrollToTop}><a href="#">Artificial Intelligence</a></Link></li>
                            <li><Link to={'/machine_learning'} onClick={scrollToTop}><a href="#">Machine Learning</a></Link></li>
                            <li><Link to={'/web_development'} onClick={scrollToTop}><a href="#">Web-designers</a></Link></li>
                            <li><Link to={'/app_development'} onClick={scrollToTop}><a href="#">App-development</a></Link></li>
                            <li><Link to={'/CRM'} onClick={scrollToTop}><a href="#">CRM</a></Link></li>
                        </ul>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 ftlinks">
                        <ul>
                            <li><h5 class="p-class">Company</h5></li>                 
                            <li><Link to={'/About'} onClick={scrollToTop}>About Us</Link> </li>
                            <li><Link to={'/Career'} onClick={scrollToTop}>Careers</Link></li>
                            <li><Link to={'/Contact'} onClick={scrollToTop}>Contact Us</Link></li>
                        </ul>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 ftlinks">
                        <ul>
                            <li><h5 class="p-class">Other</h5></li>                 
                            <li><Link to={'/'} onClick={scrollToTop}>Home</Link></li>
                        </ul>
                    </div>
                    <hr class="line mt-5 mb-5"/>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <h5 className='p-class-1'>Subscribe</h5>
                        </div>
                        {/* <div class=" newsltr_div_2  col-xs-12 col-sm-12 col-md-5 col-lg-6 col-xl-6 col-xxl-6">
                            <input type="text" class="form-control shadow" placeholder="Enter your Email Address"/>
                            <a href="#">Subscribe</a>
                        </div> */}
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <ul>
                                <li class="social-icons">
                                    <a class="instagram" href="https://www.instagram.com/balert.in/" target="_blank"><img src="../images/Instagram.png" width="20" height="20"/></a> 
                                    <a class="twitter" href="https://twitter.com/balert_in" target="_blank"><img src="../images/Twitter.png" width="20" height="20"/></a> 
                                    <a class="facebook" href="https://www.facebook.com/balertofficial/" target="_blank"><img src="../images/facebook-icon.png" width="10" height="20"/></a> 
                                    <a class="linkedin" href="https://www.linkedin.com/company/balertofficial/" target="_blank"><img src="../images/Linkedin In.png" width="20" height="20"/></a>
                                    <a class="linkedin" href="#" target="_blank"><img src="../images/youtube-icon.png" width="30" height="20"/></a>
                                </li>
                            </ul>
                        </div>   
                    </div>
                    <div class="text-center mt-4 mb-2">
                        <p className='copyRight'>© 2024 All Rights Reserved to BUZZLER Technologies</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    </div>
  )
}

export default Footer
