import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faLocationDot,faPhoneVolume,faEnvelope} from '@fortawesome/free-solid-svg-icons'
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';
function About() {
  return (
    <div>
      <section class="sect-cls">
        <div class="col_bg_5">
            <div class="row rowcust">
                <Header/>
            </div>
            <section>
                <div class="container text-center">
                    <div class="row">
                        <div class="col">
                            <h1 className='mainheading-11'><span style={{color:'black'}}>We're here to</span><br></br>gurantee your success</h1>
                            <hr class="custom-horizontal-line1"/>
                        </div>
                    </div>
                </div>
            </section>
            <section class="mt-5">
                <div class="container">
                    <p class="background-1 align buzzlertext">"At Buzzler Technologies, we're dedicated to guaranteeing your success. With innovative solutions, skilled professionals, and a client-centric approach, we ensure excellence in every project, every time."</p>
                    <img src="./images/About_Frame.png" alt="" width="100%" class="about_image"/>
                </div>
            </section>
            <section>
                <div class="container text-center">
                    <h1 class=" mainheading-12"><span style={{color:'black'}}>We’re here for you</span> <br></br>no matter where you are</h1>
                    <hr class="custom-horizontal-line1 mb-5"/>
                </div>
            </section>
            <section>
                <div class="container">
                    <img src="./images/WorldMap.png" alt="" width="100%" class="mt-5"/>
                </div>
            </section>
        </div>
        <div class="col_bg_6">
            <section>
                <div class="container mt-2 align">
                    <h1 className='mission'><span style={{color:'black'}}>Our</span> Mission</h1>
                    <hr style={{width:'60%'}}/>
                    <div class="row">
                        <div class="col-md-3">
                            <h6 className='unmatched'>Unmatched service</h6>
                        </div>
                        <div class="col-md-9">
                            <p className='support'>Support corporate clients and financial investors with their growth strategy and international development.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <h6 className='unmatched'>Specific</h6>
                        </div>
                        <div class="col-md-9">
                            <p className='support'>ConsultUs’ core expertise lies in the ability to support our clients in understanding, analysing and executing commercial and investment strategies in specific markets. </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <h6 className='unmatched'>Experience</h6>
                        </div>
                        <div class="col-md-9">
                            <p className='support'>Experience in working with and assisting a wide range of clients from international corporations to small/medium-sized businesses, from large corporate debt providers to mid-market private equity investors. </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <h6 className='unmatched'>Technology</h6>
                        </div>
                        <div class="col-md-9">
                            <p className='support'>The best combination of technology and people.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div class="container mt-5 align">
                    <h1 className='mission'><span style={{color:'black'}}>Our</span> Vision</h1>
                    <hr style={{width:'60%'}}/>
                    <div class="row">
                        <div class="col-md-3">
                            <h6 className='unmatched'>Ethics</h6>
                        </div>
                        <div class="col-md-9">
                            <p className='support'>Ethics for ConsultUs means ensuring our customers the confidentiality and <br></br>uniqueness of the service they are provided. A client must be reassured by <br></br>the fact that the service provided to them is made-to-measure and will not <br></br>be recycled for their competitors.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <h6 className='unmatched'>Quality</h6>
                        </div>
                        <div class="col-md-9">
                            <p className='support'>ConsultUs is committed to ensuring that our advice and recommendations <br></br>are based on the best combination of methods, information research, <br></br>creativity and internal quality assurance. </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <h6 className='unmatched'>Continuity</h6>
                        </div>
                        <div class="col-md-9">
                            <p className='support'>ConsultUs considers that the continuity of relations on the long term with <br></br>its clients is the guarantee of the satisfaction of these and the quality of the <br></br>services provided.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div class="container text-center">
                    <h1 className='mainheading-13'>Don't just take our word for it!</h1>
                    <h5 className='support1'>See the feedback from your teammates</h5>
                </div>
            </section> 
            <section>
                <div class="container mt-5">
                    <div class="row">
                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 align">
                            <div class="background-3 border border-primary shadow">
                                <div style={{marginLeft:'0em'}}>
                                    <h6 className='text67'>" I do admire the company culture and, I like that. Visioncraft is truly the place where good attitudes have blended with proficiency. If you’re going to craft something great and you have a strong eagerness to do that, Visioncraft will remove all hassles in your journey "</h6>
                                    <h6 className='name'>NAME</h6>
                                    <p className='designation'>Designation</p>
                                    <p style={{marginLeft:'2em'}} className='profile'><button type='button' className='btn btn-lg border border-dark rounded-pill'><img src="./images/linkedin.png" alt=""/>&nbsp;&nbsp;LinkedIn Profile</button></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <section class="sect-cls" style={{marginTop:'10em'}}>
        <div class="col_bg_3">
            <div class="container mt-1">
                <div class="row align">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div class="background">
                            <div class="col_bg-2">
                                <div>
                                    <div class="container col-md-12">
                                        <h1 className='contact'>Contact <span class="us">Us</span></h1>
                                        <form id="contactForm">
                                            <div class="form-group mt-5">
                                                <input type="text" class="form-control form-control-1" placeholder="Your Name" name="name" required/>
                                            </div>
                                            <div class="form-group mt-4">
                                                <input type="email" class="form-control form-control-1" placeholder="Email" name="email" required/>
                                            </div>
                                            <div class="form-group mt-4">
                                                <textarea class="form-control form-control-1" id="messageBox" placeholder="Message" name="messageBox" rows="4"
                                                    required></textarea>
                                            </div>
                                            <div class="form-check mt-4">
                                                <input type="checkbox" class="form-check-input" id="checkBox"
                                                    name="checkBox" required/>
                                                <label class="form-check-label" for="checkBox">I would like to receive the newsletter.</label>
                                            </div>
                                            <div className='btn-pink btn-text mt-4'>
                                                <button class="btn btn-md text-white">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-4">
                        <div class="map-margin">
                            <p className='get-in'>Get in touch with us, we love to hear and respond to <br></br>your messages.</p>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.5253765500497!2d78.37664357420597!3d17.434550583460705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb93e37831f177%3A0x32d4499d34baaa78!2sT-Hub%20Phase%202!5e0!3m2!1sen!2sin!4v1708936451341!5m2!1sen!2sin" 
                            width="400" 
                            height="250" 
                            style={{border:0}}
                            className='map_image'
                            allowfullscreen="" 
                            loading="lazy" 
                            referrerpolicy="no-referrer-when-downgrade">
                            </iframe>
                            <div className='get-in'>
                                <p class="mt-2"><FontAwesomeIcon icon={faLocationDot} />&nbsp;&nbsp;T-Hub 2.0, 20, Inorbit Mall Rd, Vittal Rao Nagar, <br></br>Madhapur, Hyderabad, Telangana 500081</p>
                                <p><FontAwesomeIcon icon={faPhoneVolume} />&nbsp;&nbsp;+91 91547 91547</p>
                                <p><FontAwesomeIcon icon={faEnvelope} />&nbsp;&nbsp;hello@buzzler.in</p>
                            </div>
                        </div>
                        <div class="text-end justify-content-between">
                            <div>
                                <a href='https://www.facebook.com/balertofficial/' target='_blank'><FaFacebook  size={20} color='black'/></a>&nbsp;&nbsp;
                                <a href="https://www.instagram.com/balert.in/" target="_blank"><FaInstagram size={20} color='black' /></a>&nbsp;&nbsp;
                                <a href="https://twitter.com/balert_in" target="_blank"><FaTwitter size={20} color='black' /></a>&nbsp;&nbsp;
                                <a><FaYoutube size={20} color='black'/></a>&nbsp;&nbsp;
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer/>
    </section>
    </div>
  )
}

export default About
