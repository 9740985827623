import React from 'react'
import Header from '../components/Header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowRight} from '@fortawesome/free-solid-svg-icons'
import Footer from '../components/Footer'
import { BrowserRouter,Link } from 'react-router-dom'
function Services() {
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      };
  return (
    <div>
      <section>
        <div>
            <div class="row rowcust">
                <Header/>  
            </div>
            <div class="container mt-5">
                <div class="row">
                    <div class="col-lg-offset-1 col-lg-6 col-md-offset-1 col-md-6 col-sm-12 col-xs-12">
                        <h2 class="mainheading1" ><span style={{color:'#000000'}}>Explore Our</span><br></br>Services</h2>
                        <p className='align sub-heading1'>We provide high quality services <br></br>and products to the users.</p>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 service-image">
                        <img src="images/Code typing.gif" class="img-responsive service-image"/>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="container">
        <h1 class="text-center mainheading-service">Services</h1>
    </div>
    <section>
        <div class="body_bg-1 center-vertically-bg" >
            <div class="container">
                <div class="row rowcust-1 align">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <img src="images/Artificial intelligence.gif" alt="" class="img-fluid serices_images"/>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" data-aos="fade-right" data-aos-delay="100">
                    <div className='btn-service btn-service1 rounded-pill'>
                        <p>&nbsp;&nbsp;&nbsp;<img src="images/Circuit Icon.png" alt=""
                            width="20" height="20"/><span className='services'> Our Services</span></p>
                    </div>
                        <h2 className='mainheading-2'>Artificial Intelligence</h2>
                        <p className='small-heading2'>AI's power, unlocking data insights, automation, and smart solutions for unparalleled innovation.</p>
                        <div className='btn-pink btn-text mt-4'>
                            <Link class="btn btn-md text-white" to={'/artificial_intelligence'} onClick={scrollToTop}>Explore More</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="body_bg-2 center-vertically-bg">
            <div class="container">
                <div class="row rowcust-1 align">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                    <div className='btn-service rounded-pill' style={{marginTop:'6em'}}>
                        <p>&nbsp;&nbsp;&nbsp;<img src="images/Circuit Icon.png" alt=""
                            width="20" height="20"/><span className='services'> Our Services</span></p>
                    </div>
                        <h2 className='mainheading-2'>Machine Learning</h2>
                        <p className='small-heading2'>Machine Learning, driving predictive analytics and data-driven solutions for transformative business intelligence."</p>
                        <div className='btn-pink btn-text mt-4'>
                            <Link class="btn btn-md text-white" to={'/machine_learning'} onClick={scrollToTop}>Explore More</Link>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <img src="images/Robot face.gif" alt="" class="img-fluid serices_images"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row rowcust-1">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <img src="images/Hand coding.gif" alt="" class="img-fluid serices_images"/>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 align">
                <div className='btn-service rounded-pill' style={{marginTop:'8em'}}>
                        <p>&nbsp;&nbsp;&nbsp;<img src="images/Circuit Icon.png" alt=""
                            width="20" height="20"/><span className='services'> Our Services</span></p>
                    </div>
                    <h2 className='mainheading-2'>Web Development</h2>
                    <p className='small-heading2'>crafting seamless, responsive sites with cutting-edge technology for unparalleled user experiences.</p>
                    <div className='btn-pink btn-text mt-4'>
                        <Link class="btn btn-md text-white" to={'/web_development'} onClick={scrollToTop}>Explore More</Link>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row rowcust-1">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 align">
                <div className='btn-service rounded-pill' style={{marginTop:'6em'}}>
                        <p>&nbsp;&nbsp;&nbsp;<img src="images/Circuit Icon.png" alt=""
                            width="20" height="20"/><span className='services'> Our Services</span></p>
                    </div>
                    <h2 className='mainheading-2'>Customer Relation Management</h2>
                    <p className='small-heading2'>Tailored solutions, streamlined processes, and customer-centric strategies for optimal business relationships and growth.</p>
                    <div className='btn-pink btn-text mt-4'>
                        <Link class="btn btn-md text-white" to={'/CRM'} onClick={scrollToTop}>Explore More</Link>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <img src="images/Customer relationship management.gif" alt="" class="img-fluid serices_images"/>
                </div>
            </div>
        </div>
        <div class="body_bg-1 center-vertically-bg">
            <div class="container">
                <div class="row rowcust-1">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <img src="images/Advanced customization.gif" alt="" class="img-fluid serices_images"/>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 align">
                    <div className='btn-service rounded-pill' style={{marginTop:'6em'}}>
                        <p>&nbsp;&nbsp;&nbsp;<img src="images/Circuit Icon.png" alt=""
                            width="20" height="20"/><span className='services'> Our Services</span></p>
                    </div>
                        <h2 className='mainheading-2'>App Development</h2>
                        <p className='small-heading2'>Excels in app development, crafting intuitive, feature-rich applications for seamless user experiences and market success.</p>
                        <div className='btn-pink btn-text mt-4'>
                            <Link class="btn btn-md text-white" to={'/app_development'} onClick={scrollToTop}>Explore More</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-8 col-lg-8 col-sm-12 col-xs-12">
                    <div class="p-2 text-center box-modal-1 border rounded shadow">
                        <div class="text-center d-flex justify-content-around">
                        <div>
                            <h1 className='register'>Register using email address </h1>
                            <h4 className='mail'>hello@buzzler.in</h4>
                        </div>
                        <div className='btn-pink btn-text justify-content-end'>
                            <button class="btn btn-lg text-white"><a href="mailto:hello@buzzler.in" style={{textDecoration:'none',color:'#ffffff'}}>Subscribe</a></button>
                        </div>
                        </div>
                    </div>
                    <img src="./images/MaskGroup.png" alt="" className='maskgrop-image'/>
                </div>
                <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 justify-content-center">
                    <img src="./images/Mask group.png" alt=""/>
                </div>
            </div>
        </div>
    </section>
    <section class="body_bg-3">
        <div class="container align">
            <div class="row">
                <div class="col-md-5 col-sm-6 col-xs-12 col-lg-5">
                    <h2 class="place">Best place to <br></br>get the services <br></br></h2>
                </div>
                <div class="col-md-7 col-lg-7 col-xs-6 col-xs-12 d-flex justify-content-end">
                    <img src="./images/crm1.png" alt="" width="80%" class="image-crm"/>
                </div>
            </div>
            <div class="row">
                <div class="col-md-5 col-sm-6 col-xs-12 col-lg-5">
                    <p className='text-66'>“Choose Buzzler Technologies for unparalleled software solutions. Our expertise in AI, mobile apps, websites, digital marketing, and CRM ensures exceptional results.”</p>
                </div>
                <div class="col-md-7 col-lg-7 col-xs-6 col-xs-12">
                    <img src="./images/crm2.png" alt="" width="80%"/>
                </div>
            </div>
        </div>
        <div class="container mt-5">
            <div class="row align">
                <div class="col d-flex justify-content-end">
                    <img src="./images/Image12.png" alt="" width="100" height="100"/>
                </div>
                <div class="col mt-5">
                    <h2 className='place1'>It’s possible to get <br></br>secure services and <br></br>Loyal operations</h2>
                    <p class="mt-5 text-66">"Secure services and loyal operations are guaranteed with Buzzler Technologies, ensuring trust and reliability in every endeavour."</p>
                </div>
            </div>
        </div>
        <div class="body_bg-4">
            <div class="container" style={{marginTop:'15em'}}>
                <div class="row rowcust align">
                    <div class="col-md-6 col-sm-12 col-xs-12 col-lg-6">
                        <h2 className='mainheading'>Browse our <br></br>Case Study</h2>
                        <p className='text-66'>"Explore our Case Studies for in-depth insights <br/>into successful projects."</p>
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12 col-lg-6">
                        <div class="d-flex justify-content-between">
                            <p class="mt-2 one">01</p>
                            <h3 className='arti'>Artificial Intelligence</h3>
                            <FontAwesomeIcon icon={faArrowRight} className='arti1'/>
                        </div>
                        <p class="text-center case">3 Case Studies</p>
                        <hr style={{width:'100%'}}/>
                        <div class="d-flex justify-content-between">
                            <p class="mt-2 one">02</p>
                            <h3 className='arti'>Machine Learning</h3>
                            <FontAwesomeIcon icon={faArrowRight} className='arti1'/>
                        </div>
                        <p class="text-center case">3 Case Studies</p>
                        <hr style={{width:'100%'}}/>
                        <div class="d-flex justify-content-between">
                            <p class="mt-2 one">03</p>
                            <h3 className='arti'>Web Development</h3>
                            <FontAwesomeIcon icon={faArrowRight} className='arti1'/>
                        </div>
                        <p class="text-center case">3 Case Studies</p>
                        <hr style={{width:'100%'}}/>
                        <div class="d-flex justify-content-between">
                            <p class="mt-2 one">04</p>
                            <h3 className='arti'>CRM</h3>
                            <FontAwesomeIcon icon={faArrowRight} className='arti1'/>
                        </div>
                        <p class="text-center case">3 Case Studies</p>
                        <hr style={{width:'100%'}}/>
                        <div class="d-flex justify-content-between">
                            <p class="mt-2 one">05</p>
                            <h3 className='arti'>App Development</h3>
                            <FontAwesomeIcon icon={faArrowRight} className='arti1'/>
                        </div>
                        <p class="text-center case">3 Case Studies</p>
                        <div>
                            <button class="btn btn-light rounded-pill btn-lg justify-content-end mt-5 mb-5 text-expert"><span><img src="./images/Arrow.png" alt="" width="50" height="50"/></span>&nbsp;&nbsp;More&nbsp;&nbsp;&nbsp;&nbsp;</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </section>
    </div>
  )
}

export default Services
